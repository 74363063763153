<template>
    <div class="my-coupons">
        <b-table class="table mt-1" responsive :items="data" :fields="fields">
            <template #cell()="row">
                <template v-if="row.field.type === 1">
                    {{ dayjs(row.item[row.field.key]).format('DD MMMM YYYY') }}
                </template>
                <template v-else-if="row.field.type === 2">
                    {{ row.item['obracun_leto'] }} / {{ row.item['obracun_mesec'] }}-{{ row.item['obracun_vrsta'] }}
                </template>
                <template v-else-if="row.field.type === 3">
                    <template v-if="row.item['zaprto']">
                        {{ dayjs(row.item['datum_zadnjega_placila']).format('DD.MM.YYYY') }}
                    </template>
                    <template v-else>
                        /
                    </template>
                </template>
                <template v-else>
                    {{ row.item[row.field.key]}}
                </template>
            </template>
            <template #custom-foot>
                <th colspan="2" style="font-weight: 900;">Skupaj</th>
                <th  style="font-weight: 900;">{{parseInt(data.reduce((a, b) => a + b.points, 0))}}</th>
            </template>
        </b-table>
    </div>
</template>

<script>
    import {BTable} from 'bootstrap-vue'

    export default {
        components: {
            BTable
        },
        data() {
            return {
                fields: [
                    { key: 'text', label: 'Razlog pridobitve', sortable: false, class: 'text-left', type: 0 },
                    { key: 'date', label: 'Datum', sortable: false, class: 'text-left', type: 1 },
                    { key: 'points', label: 'Število točk', sortable: false, class: 'text-left', type: 0 }
                ]
            }
        },
        props: {
            data:{
                type: Array,
                required: true
            }
            
        }
    }
</script>

<style>
.my-coupons .table th {
  background-color: #dbe9f5  !important;
  border-top: none;
  border-bottom: none;
  text-transform: none;
}

</style>