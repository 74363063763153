import { render, staticRenderFns } from "./MyCoupons.vue?vue&type=template&id=06b49870&scoped=true&"
import script from "./MyCoupons.vue?vue&type=script&lang=js&"
export * from "./MyCoupons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b49870",
  null
  
)

export default component.exports