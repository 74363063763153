<template>
    <div class="w-100 my-coupons">
        <h1 class="mb-3" style="font-weight: bolder; color: #72A5D8; font-size: xx-large">Moji Kuponi</h1>
        <div style="background-color: #ededed" class="w-75">
            <div class="d-flex justify-content-start" style="padding-bottom: 2rem; padding-top: 2rem; padding-left: 2rem">
                <fa icon="tag" class="fa-flip-horizontal" style="width: 4rem; height: 4rem; color: #72A5D8"/>
                <div class="d-flex align-items-center ml-2">
                    <h1 style="font-weight: bold; padding-right: 7px">Imate</h1>
                    <h1 style="font-weight: bold; color: #72A5D8">{{number_of_coupons}} {{coupons_text}}</h1>
                </div>
            </div>
            <div style="display: grid; grid-template-columns: auto auto; background-color: #72A5D8; padding-top: 1rem; padding-bottom: 1rem">
                <div class="d-flex align-items-center pl-2">
                    <h1 style="font-weight: bold; color: white; font-size: 1.2rem;" >ZBRANE TOČKE</h1>
                </div>
                <div>
                    <div class="d-flex justify-content-end mr-4" v-if="points && points.total_points">
                        <h1 style="font-weight: bold; color: white; font-size: 1.2rem; margin-right: 3rem">{{points.total_points}} TOČK</h1>
                    </div>
                    <div class="d-flex justify-content-end">
                        <span style="color: white; font-size: 0.7rem; margin-right: 2rem">(Še {{points_to_next_coupon}} točk do novega kupona)</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="points_data">
            <h1 class="mt-3 mb-2" style="font-weight: bolder; color: #72A5D8; font-size: xx-large">Seznam pridobljenih točk</h1>
            <MyCouponsPointsList :data="points_data"/>
        </div>

        <div v-if="config_points">
            <h1 class="mt-3 mb-2" style="font-weight: bolder; color: #72A5D8; font-size: xx-large">Več o točkah in kuponih</h1>
            <CollapseMyProfile type="faq-points-get" :data="config_points" class="my-1"/>
        </div>
        
    </div>
</template>

<script>
    /*import {BCard, BButton} from 'bootstrap-vue'*/
    import CollapseMyProfile from '@/views/Components/CollapseMyProfile'
    // import MyCouponsPointsDesc from '@/views/Components/MyCouponsPointsDesc'
    import MyCouponsPointsList from '@/views/Components/MyCouponsPointsList'

    export default {
        components: {
            CollapseMyProfile,
            // MyCouponsPointsDesc,
            MyCouponsPointsList
        },
        data() {
            return {
                points_data: [],
                points: null,
                config_points: [],
                number_of_coupons: 0,
                points_to_next_coupon: 0,
                coupons_text: ''
            }
        },
        methods:{
            calculateCoupons() {

                if (this.points) {
                    this.number_of_coupons = parseInt(this.points / 300)
                    this.points_to_next_coupon = 300 - (Number(this.points) % 300)

                    if (this.number_of_coupons === 1) {
                        this.coupons_text = 'kupon'
                    } else if (this.number_of_coupons === 2) {
                        this.coupons_text = 'kupona'
                    } else if (this.number_of_coupons <= 4) {
                        this.coupons_text = 'kupone'
                    } else {
                        this.coupons_text = 'kuponov'
                    }
                }
                
            },
            async loadData() {
                try {
                    const res = await this.$http.get('/api/user/v1/points/get_collected_points_list')
                    this.points_data = res.data

                    const res_points_total = await this.$http.get('/api/user/v1/points/get_collected_points')
                    this.points = res_points_total.data
                    this.calculateCoupons()

                    const res_config_points = await this.$http.get('/api/user/v1/points/get_config_points_list')
                    this.config_points = res_config_points.data
                } catch (err) {
                    this.$printError('Prislo je do napake pri nalaganju podatkov')
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>